import React from 'react'
import styled from 'styled-components'
import { ContactFormData } from '.'
import CodeHighlighter from '../../../components/CodeHighlighter'

const StyledConsoleWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`

const StyledConsoleContent = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  min-height: 100%;
`

interface IConsole {
  formData: ContactFormData
}

const Console = (props: IConsole) => {
  const { formData } = props;

  const string =
    `const handleSubmit = (e) => {
  e.preventDefault();
  const requestData = {
    _name : '${formData._name}',
    _email: '${formData._email}',
    _message: '${formData._message}'
  }

  // api call to post message
  postMessage(requestData);
}
  `

  return (
    <StyledConsoleWrapper>
      <StyledConsoleContent>
        <CodeHighlighter string={string} />
      </StyledConsoleContent>
    </StyledConsoleWrapper>
  )
}

export default Console