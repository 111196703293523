import styled from 'styled-components';
import { ThemeProps } from '../../../@types/theme';
import { useToggle } from '../../../hooks/useToggle';
import { IStacksData } from '../../../pages/Projects';
import SidebarItem from './SidebarItem';

const StyledSideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  width: 240px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;

  @media (max-width: 500px){
    width: 100%;
    height: auto;
  }
`;

const StyledSideNabTitle = styled.span`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  display: flex;
  gap: 4px;
  align-items: center;
`

const StyledSideNabIcon = styled.span`
  font-size: 20px;
`

const StyledProjectWrapper = styled.div`
   display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
`

const StyledBlockHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: inherit;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  
  :hover {
    cursor: pointer;
  }
`

export const StyledTechIcon = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 10px;

  &.accent1{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent1}
  }

  &.accent2{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2}
  }

  &.accent3{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3}
  }
`

interface IProps {
  stacks: IStacksData[];
  setStacks: (stacks: IStacksData[]) => void;
}


const ProjectSideBar = (props: IProps) => {
  const { stacks, setStacks } = props;

  const [isProjectsOnen, toggleProjects] = useToggle(true);

  const handleCheck = (selectedStack: IStacksData) => {
    const updatedStack = stacks.map((stack) => {
      if (stack.name === selectedStack.name) {
        stack.isChecked = !stack.isChecked
      }
      return stack;
    })
    setStacks(updatedStack)
  }

  return (
    <>
      <StyledSideNavWrapper>
        <StyledBlockHeader onClick={toggleProjects}>
          <StyledSideNabTitle>
            {isProjectsOnen ? (
              <StyledSideNabIcon>
                <i className="ri-arrow-down-s-fill"></i>
              </StyledSideNabIcon>
            ) : (
              <StyledSideNabIcon>
                <i className="ri-arrow-right-s-fill"></i>
              </StyledSideNabIcon>
            )}
            projects
          </StyledSideNabTitle>
        </StyledBlockHeader>
        {isProjectsOnen && (
          <StyledProjectWrapper >
            {
              stacks.map((stack: IStacksData) => (
                <SidebarItem key={stack.name} stack={stack} handleCheck={handleCheck} />
              ))
            }
          </StyledProjectWrapper>
        )}
      </StyledSideNavWrapper>
    </>
  )
}

export default ProjectSideBar