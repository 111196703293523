import CodeHighlighter from "../../../../components/CodeHighlighter"

const Bachelor = () => {

  const string =
    `const overview = {
  degree: 'Bsc (Hons) Computing'
  course: 'Information Technology'
  institude: 'Softwarica College of IT & E-commerce',
  completedYear: 2020,
  degreeAwardedBy: 'Coventry University'
}

const modules = {
  firstSem: [
    'Computer System',
    'Software Development Techniques',
    'Database',
    'Skill For Computing'
  ],
  secondSem: [
    'Designing and Developing Object-Oriented', 
    'Computer Programs',
    'Computer Network', 
    'Office Solution Development', 
    'Design and Development Website'
  ],
  thirdSem: [
    'Dynamic Website',
    'Analysis Design and Implementation',
    'Network Security and Cryptography',
    'Information System Analysis'
  ],
  fourthSem: [
    'Computing Project',
    'Agile Development',
    'Professional Issues in IT', 
    'Database Design and Development'
  ],
  finalYear : [
    'Academic Writing',
    'Individual Project', 
    'Dissertation',
    'Android Application Development',
    'Wep API Development',
    'Agile Development',
    'Interactive Pervasive Computing'
  ]
}
`

  return (
    <CodeHighlighter string={string} />
  )
}

export default Bachelor