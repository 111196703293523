import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../@types/theme';
import DefaultProjectImage from '../../../assets/image/900.png';
import ProjectModal from './ProjectModal';
import { IProjectItem } from '../project.data';

const StyledProjectItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  /* @media (max-width: 500px){
    display: none;
  } */
`;

const StyledProjecCard = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  outline: ${({ theme }: { theme: ThemeProps }) => `1px solid ${theme.colors.lines}`};
  .hide {
    visibility: hidden;
  }

  &:hover {
    .hide {
      visibility: visible;
      background-color: rgba(4, 33, 50, 0.568);
      border-radius: 8px;
      transition: 0.4s;
    }
  }
`;
const StyledProjecTitle = styled.h4`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent4};
  margin-bottom: 8px;
`;

const StyledProjecImg = styled.img`
  object-fit: cover;
  width: 100%;
`;

const StyledButtonWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  &:hover {
    visibility: visible;
  }
`;

const StyledButton = styled.button`
  width: 150px;
  outline: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2};
  border-radius: 4px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2};

  &:hover {
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnPrimaryText};
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2};
  }
`;

const ProjectItem = ({ project }: { project: IProjectItem }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <StyledProjectItem>
        <StyledProjecTitle>{project.title}</StyledProjecTitle>
        <StyledProjecCard>
          <StyledProjecImg src={DefaultProjectImage} />
          <StyledButtonWrapper className="hide">
            <StyledButton onClick={toggleModal}>View Detail</StyledButton>
            <StyledButton>Demo</StyledButton>
            <StyledButton>Code</StyledButton>
          </StyledButtonWrapper>
        </StyledProjecCard>
      </StyledProjectItem>
      <ProjectModal data={project} isOpen={isModalOpen} toggle={toggleModal} />
    </>
  );
};

export default ProjectItem;
