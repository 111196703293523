import styled from 'styled-components';
import { ThemeProps } from '../../../@types/theme';
import { useToggle } from '../../../hooks/useToggle';

const StyledSideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  width: 240px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;

  @media (max-width: 500px){
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
`;

const StyledSideNabTitle = styled.span`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  display: flex;
  gap: 4px;
  align-items: center;
`

const StyledSideNabIcon = styled.span`
  font-size: 20px;
`

const StyledContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
`

const StyledContactLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  
`

const StyledBlockHeader = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: inherit;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};

  :hover {
    cursor: pointer;
  }
`

const StyledContactInfoItem = styled.span`
  display: flex;
  align-items: flex-start;
  padding: 4px 15px;
`

const StyledContactLinkItem = styled.a`
  display: flex;
  align-items: center;
  padding: 4px 15px;
  text-decoration: none;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  &:visited, &:active{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.socialMedia};
  }
  &:hover{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.socialMediaHover};
  }
`

export const StyledInfoIcon = styled.span`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 10px;
`

const ContactSideBar = () => {

  const [isContactInfoOnen, toggleContactInfo] = useToggle(true);
  const [isFindInfoOnen, toggleFindInfo] = useToggle(true);

  return (
    <>
      <StyledSideNavWrapper>
        <StyledBlockHeader onClick={toggleContactInfo}>
          <StyledSideNabTitle>
            {isContactInfoOnen ? (
              <StyledSideNabIcon>
                <i className="ri-arrow-down-s-fill"></i>
              </StyledSideNabIcon>
            ) : (
              <StyledSideNabIcon>
                <i className="ri-arrow-right-s-fill"></i>
              </StyledSideNabIcon>
            )}
            contacts
          </StyledSideNabTitle>
        </StyledBlockHeader>
        {isContactInfoOnen && (
          <StyledContactInfoWrapper>
            <StyledContactInfoItem>
              <StyledInfoIcon>
                <i className="ri-mail-fill"></i>
              </StyledInfoIcon>
              bhattaraibikash55
              @gmail.com
            </StyledContactInfoItem>
          </StyledContactInfoWrapper>
        )}
        <StyledBlockHeader onClick={toggleFindInfo}>
          <StyledSideNabTitle>
            {isFindInfoOnen ? (
              <StyledSideNabIcon>
                <i className="ri-arrow-down-s-fill"></i>
              </StyledSideNabIcon>
            ) : (
              <StyledSideNabIcon>
                <i className="ri-arrow-right-s-fill"></i>
              </StyledSideNabIcon>
            )}
            find-me-also-in
          </StyledSideNabTitle>
        </StyledBlockHeader>
        {isFindInfoOnen && (
          <StyledContactLinksWrapper>
            <StyledContactLinkItem href='https://www.linkedin.com/in/bethebikash' target="_blank">
              <StyledInfoIcon>
                <i className="ri-external-link-fill"></i>
              </StyledInfoIcon>
              LinkedIn
            </StyledContactLinkItem>
            <StyledContactLinkItem href='https://www.facebook.com/bethebikash' target="_blank">
              <StyledInfoIcon>
                <i className="ri-external-link-fill"></i>
              </StyledInfoIcon>
              Facebook
            </StyledContactLinkItem>
            <StyledContactLinkItem href='https://www.instagram.com/self_bikash' target="_blank">
              <StyledInfoIcon>
                <i className="ri-external-link-fill"></i>
              </StyledInfoIcon>
              Instagram
            </StyledContactLinkItem>
            <StyledContactLinkItem href='https://www.twitter.com/self_bikash' target="_blank">
              <StyledInfoIcon>
                <i className="ri-external-link-fill"></i>
              </StyledInfoIcon>
              Twitter
            </StyledContactLinkItem>
          </StyledContactLinksWrapper>
        )}
      </StyledSideNavWrapper>
    </>
  )
}

export default ContactSideBar