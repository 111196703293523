import CodeHighlighter from '../../../../components/CodeHighlighter'

const Abt = () => {
  const string =
    `const overview = {
  institution: 'Alpha Beta Theta Technologies',
  startDate: Jan 2020,
  endDate: May 2021,
  jobTitle: 'Frontent Developer - React',
  designation: 'Jr. Developer',
}

const keyPoints = [
  'Worked in React.js using typescript, hooks, redux, styled components, ant designs and framer motion.',
  'Developed the latest user-facing features and built reusable components for future use.',
  'Reviewed application requirements and interface design to ensure compatibility with existing application.',
]
`

  return (
    <CodeHighlighter string={string} />
  )
}

export default Abt