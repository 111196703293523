import React, { PropsWithChildren } from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../@types/theme';

const StyledHeader = styled.header`
  height: 50px;
  flex-shrink: 0;
  border-bottom: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
`;

const Header = (props: PropsWithChildren) => {
  return (
    <StyledHeader>
      {props.children}
    </StyledHeader>
  )
}

export default Header