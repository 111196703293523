import CodeHighlighter from "../../../../components/CodeHighlighter"

const Introduction = () => {

  const string =
    `const intro = {
  name: 'Bikash Bhattarai',
  address: 'Jadibuti, Kathmandu'
}

// Bio
/* 
Hello there! I'm a passionate frontend developer with a knack for creating captivating user experiences.

I have a strong command of HTML, CSS, and JavaScript, and I pride myself on writing clean, efficient, and maintainable code. I love the challenge of turning complex designs into pixel-perfect, responsive websites that not only look stunning but also provide seamless interactions for users across various devices and platforms.

In addition to my frontend skills, I possess a valuable understanding of backend technologies. I have delved into server-side programming languages like Node.js and have experience working with databases, API integrations, and server management. This comprehensive knowledge enables me to collaborate effectively with backend developers and bridge the gap between the frontend and backend aspects of a project.

Throughout my career, I have had the pleasure of working on diverse projects, ranging from corporate websites to fintech solution. I thrive in a collaborative environment and am comfortable working with cross-functional teams, designers, and stakeholders. I believe that effective communication and attention to detail are paramount in delivering exceptional results.

I am a firm believer in continuous learning and enjoy taking on new challenges to expand my horizons.

I'm excited about the opportunity to bring your ideas to life and create immersive digital experiences that leave a lasting impact. Feel free to browse through my portfolio and get in touch — I'd love to discuss how we can work together to achieve your goals. 
**/
`

  return (
    <CodeHighlighter string={string} />
  )
}

export default Introduction