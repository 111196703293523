import React from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../../@types/theme';

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 500px){
    display: none;
  }
`;

const StyledFooterMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  height: 100%;
`;

const StyledMenuItem = styled.li`
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  flex-shrink: 0;
  height: 100%;
`;

const StyledText = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
`;

const StyledMenuGitHub = styled.div`
  border-left: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  flex-shrink: 0;
`;


const StyledLink = styled.a`
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  &:visited, &:active {
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.socialMedia};
  }
  &:hover{
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.socialMediaHover};
  }
`;

const StyledFooterForMobile = styled.div`
  display: none;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 500px){
    display: flex;
  }
`;

const StyledFooterIconContainerForMobile = styled.div`
  display: felx;
`;


const FooterContent = () => {
  return (
    <>
      <StyledFooter>
        <StyledFooterMenu>
          <StyledMenuItem>
            <StyledText>
              find me in:
            </StyledText>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledLink href="https://www.linkedin.com/in/bethebikash/" target="_blank">
              <i className="ri-linkedin-fill"></i>
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledLink href="https://instagram.com/self_bikash" target="_blank">
              <i className="ri-instagram-fill"></i>
            </StyledLink>
          </StyledMenuItem>
        </StyledFooterMenu>
        <StyledMenuGitHub>
          <StyledLink href="https://github.com/bethebikash" target="_blank">
            <span>@bethebikash</span>
            &nbsp;
            <i className="ri-github-fill"></i>
          </StyledLink>
        </StyledMenuGitHub>
      </StyledFooter>
      <StyledFooterForMobile>
        <StyledText>
          find me in:
        </StyledText>
        <StyledFooterIconContainerForMobile>
          <StyledMenuGitHub>
            <StyledLink href="https://www.linkedin.com/in/bethebikash/" target="_blank">
              <i className="ri-linkedin-fill"></i>
            </StyledLink>
          </StyledMenuGitHub>
          <StyledMenuGitHub>
            <StyledLink href="https://instagram.com/self_bikash" target="_blank">
              <i className="ri-instagram-fill"></i>
            </StyledLink>
          </StyledMenuGitHub>
          <StyledMenuGitHub>
            <StyledLink href="https://github.com/bethebikash" target="_blank">
              <i className="ri-github-fill"></i>
            </StyledLink>
          </StyledMenuGitHub>
        </StyledFooterIconContainerForMobile>
      </StyledFooterForMobile>
    </>
  )
}

export default FooterContent