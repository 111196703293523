import React from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../../@types/theme';
import SnakeGame from '../../components/SnakeGame';

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 2vw;
  @media (max-width: 500px){
    padding: 30px;
  }
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
`;

const StyledGap = styled.div`
  width: 240px;
`

const StyledLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  gap: 60px;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  position: relative;

  @media (max-width: 500px){
    display: none;
  }
`;

const StyledRightBackground = styled.div`
  position: absolute;
`;

const StyledTopWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBottomWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledGreeting = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
`;

const StyledName = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  font-size: 50px;
  padding: 0;
`;

const StyledProfession = styled.div`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary3};
  font-size: 24px;
`;

const StyledCommentText = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  font-size: 16px;
  margin: 0;
`;

const StyledConst = styled.span`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary3};
`;

const StyledVariable = styled.span`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary2};
`;

const StyledEqual = styled.span`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
`;

const StyledLink = styled.a`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3};
  text-decoration: none;
  &:visited, &:active {
    text-decoration: none;
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3};
  }
`;

const StyledGreenBlur = styled.div`
  position: absolute;
  width: 18vw;
  height: 18vw;
  top: 25vh;
  left: 10vw;
  display: inline-block;
  overflow: hidden;
  background: ${({ theme }: { theme: ThemeProps }) => theme.colors.gradient2};
  opacity: 0.4;
  filter: blur(74px);
  transform: rotate(-94.3deg);
`;

const StyledBlueBlur = styled.div`
  position: absolute;
  width: 16vw;
  height: 18vw;
  top: 40vh;
  left: 25vw;
  display: inline-block;
  overflow: hidden;
  background: ${({ theme }: { theme: ThemeProps }) => theme.colors.gradient1};
  opacity: 0.4;
  filter: blur(74px);
  transform: rotate(115.51deg);
`;

const StyledWorkShowcaseWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Home = () => {
  return (
    <StyledWrapper>
      <StyledLeftWrapper>
        <StyledGap>
        </StyledGap>
        <StyledLeftContent>
          <StyledTopWraper>
            <StyledGreeting>
              Hi all. I am
            </StyledGreeting>
            <StyledName>
              Bikash Bhattari
            </StyledName>
            <StyledProfession>
              {`> Front-end developer`}
            </StyledProfession>
          </StyledTopWraper>
          <StyledBottomWraper>
            <StyledCommentText>
              {`// you can see my profile on github`}
            </StyledCommentText>
            <StyledCommentText>
              <StyledConst>const</StyledConst>
              <StyledVariable>{` githubLink`}</StyledVariable>
              <StyledEqual>
                {` = `}
              </StyledEqual>
              <StyledLink href='https://github.com/bethebikash' target='_blank'>“https://github.com/bethebikash”</StyledLink>
            </StyledCommentText>
          </StyledBottomWraper>
        </StyledLeftContent>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <StyledRightBackground>
          <StyledGreenBlur>
          </StyledGreenBlur>
          <StyledBlueBlur>
          </StyledBlueBlur>
        </StyledRightBackground>
        <StyledWorkShowcaseWrapper>
          <SnakeGame />
        </StyledWorkShowcaseWrapper>
      </StyledRightWrapper>
    </StyledWrapper>
  )
}

export default Home