import styled from 'styled-components'
import { StyledTechIcon } from '.'
import { ThemeProps } from '../../../@types/theme'
import { IStacksData } from '../../../pages/Projects'

const StyledProjectItem = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  &:hover{
    background-color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.primary2};
    cursor: pointer;
  }
  .active{
    color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  }
`

const StyledCheckIcon = styled.span`
  display: flex;
  align-items: center;
  font-weight: 200;
  font-size: 20px;
  margin-right: 10px;
`

interface IProps {
  stack: IStacksData;
  handleCheck: (stack: IStacksData) => void;
}

const SidebarItem = (props: IProps) => {
  const { stack, handleCheck } = props;

  return (
    <>
      <StyledProjectItem onClick={() => handleCheck(stack)}>
        <StyledCheckIcon>
          {stack.isChecked ? (
            <i className='ri-checkbox-fill' />
          ) : (
            <i className='ri-checkbox-blank-line'></i>
          )}
        </StyledCheckIcon>
        <StyledTechIcon>
          <i className={stack.icon}></i>
        </StyledTechIcon>
        <span className={stack.isChecked ? 'active' : ''}>
          {stack.name}
        </span>
      </StyledProjectItem>
    </>
  )
}

export default SidebarItem