import styled from 'styled-components';
import { ThemeProps } from '../../../@types/theme';
import DefaultProjectImage from '../../../assets/image/900.png';
import { IProjectItem } from '../project.data';

const StyledModalWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.68); /* Black w/ opacity */
`;

const StyledModalContentWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.primary1};
  border: solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines} 1px;
  border-radius: 6px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: calc(80%);
  height: calc(80%);
  overflow: hidden;
`;

const StyledToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines} 1px;
`;

const StyledHeaderText = styled.h5`
  padding-left: 20px;
  margin: 0;
`;

const StyledCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  font-size: 24px;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  cursor: pointer;
  &:hover {
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3};
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.primary2};
  }
`;

const StyledProjectTitle = styled.h3`
  font-size: 20px;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2};
`;

const StyledProjectSubTitle = styled.p`
  padding-bottom: 20px;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
`;

const StyledModalBody = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const StyledModalLeft = styled.div`
  display: block;
  width: 50%;
  height: 100%;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};

  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledModalRight = styled.div`
  padding: 16px;
  width: 50%;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const StyledProjecImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DescriptionWrap = styled.div`
  padding-bottom: 20px;
`;
const Description = styled.div`
  flex-direction: column;
  gap: 20px;
  p:not(:last-child) {
    padding-bottom: 16px;
  }
`;

const TechWrap = styled.div`
  padding-bottom: 20px;
`;

const ActionWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  a {
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3};
    &:hover,
    :visited,
    :active {
      color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3};
    }
  }
`;

const StyledInfoHeader = styled.h3`
  font-size: 18px;
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary3};
  padding-bottom: 6px;
`;

const StyledPillsWraper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTechPills = styled.span`
  border-radius: 16px;
  font-size: 14px;
  background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnPrimaryText};
  padding: 4px 12px;
`;

interface IModal {
  isOpen: boolean;
  toggle: () => void;
  data: IProjectItem;
}

const ProjectModal = (props: IModal) => {
  const { isOpen = false, toggle, data } = props;

  return isOpen ? (
    <StyledModalWrapper>
      <StyledModalContentWrapper>
        <StyledToolBar>
          <StyledHeaderText>{data.title}</StyledHeaderText>
          <StyledCloseIcon onClick={toggle}>
            <i className="ri-close-fill"></i>
          </StyledCloseIcon>
        </StyledToolBar>

        <StyledModalBody>
          <StyledModalLeft>
            <StyledProjecImg src={DefaultProjectImage} />
          </StyledModalLeft>
          <StyledModalRight>
            <DescriptionWrap>
              <StyledProjectTitle>{data.title}</StyledProjectTitle>
              <StyledProjectSubTitle>{data?.subTitle || ''}</StyledProjectSubTitle>
              <StyledInfoHeader>Project Description</StyledInfoHeader>
              <Description>{data.description}</Description>
            </DescriptionWrap>
            <TechWrap>
              <StyledInfoHeader>Technology Used</StyledInfoHeader>
              <StyledPillsWraper>
                {data.technologies.map(tech => (
                  <StyledTechPills>{tech}</StyledTechPills>
                ))}
              </StyledPillsWraper>
            </TechWrap>
            <ActionWrap>
              {data.demoLink && (
                <div>
                  <StyledInfoHeader>Code</StyledInfoHeader>
                  <a href={data.codeLink} rel="noreferrer" target='_blank'>{data.codeLink}</a>
                </div>
              )}
              {data.demoLink && (
                <div>
                  <StyledInfoHeader>Demo</StyledInfoHeader>
                  <a href={data.demoLink} rel="noreferrer" target='_blank'>{data.demoLink}</a>
                </div>
              )}
            </ActionWrap>
          </StyledModalRight>
        </StyledModalBody>
      </StyledModalContentWrapper>
    </StyledModalWrapper>
  ) : null;
};

export default ProjectModal;
