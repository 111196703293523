import React, { useState } from 'react'
import styled from 'styled-components';
import { ThemeProps } from '../../../@types/theme';
import Console from './Console';
import Form from './Form';

const StyledContactContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  @media (max-width: 500px){
    flex-direction: column;
  }
`;

const StyledContactLeft = styled.div`
  display: flex;
  border-right: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  width: 50%;
  overflow: hidden;

  @media (max-width: 500px){
    width: 100%;
    border-right: none;
  }
`;

const StyledContactRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  @media (max-width: 500px){
    display: none;
  }
`;

export interface ContactFormData {
  _name: string;
  _email: string;
  _message: string;
}

export const initialFormData: ContactFormData = {
  _name: '',
  _email: '',
  _message: ''
}

const ContactContent = () => {

  const [formData, setFormData] = useState<ContactFormData>(initialFormData)

  return (
    <StyledContactContentWrapper>
      <StyledContactLeft>
        <Form formData={formData} setFormData={setFormData} />
      </StyledContactLeft>
      <StyledContactRight>
        <Console formData={formData} />
      </StyledContactRight>
    </StyledContactContentWrapper>
  )
}

export default ContactContent