import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import theme from 'react-syntax-highlighter/dist/cjs/styles/hljs/gruvbox-dark'

interface IProps {
  string: string;
  hideLineNumber?: boolean;
}
const CodeHighlighter = (props: IProps) => {
  const { string, hideLineNumber } = props;

  return (
    <SyntaxHighlighter style={theme} lineNumberStyle={{ marginRight: '15px' }} CodeTag={'span'} customStyle={{ backgroundColor: 'inherit', color: 'inherit', font: 'inherit', display: 'block' }} showLineNumbers={!hideLineNumber} language='typescript' wrapLongLines>
      {string}
    </SyntaxHighlighter>
  )
}

export default CodeHighlighter