import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { ContactFormData, initialFormData } from '.'
import { ThemeProps } from '../../../@types/theme'
import { GOOGLE_SCRIPT_URL } from '../../../constants/google'
import { REG_EX } from '../../../constants/regEx'

const StyledFormWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`

const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  gap: 5px;
  width: 100%;
  max-width : 400px;
`

const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 8px 16px;
  background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefault};
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefaultText};

  &:hover,:focus {
    cursor: pointer;
    transition: .3s;
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefaultHover};
  }
  &:disabled {
    cursor: default;
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefault};
  }
`

const StyledMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`

const StyledThankYou = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
  font-size: 1.8rem;
`

const StyledMessageText = styled.p`
  color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
  max-width: 400px;
  text-align: center;
`

interface IForm {
  formData: ContactFormData;
  setFormData: any;
}

const Form = (props: IForm) => {
  const { formData, setFormData } = props;

  const [showSubmitted, setShowSubmitted] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ContactFormData>(initialFormData);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    if (!value) {
      setErrors((prevState: ContactFormData) => ({ ...prevState, [name]: 'Required!' }));
    } else if (name === '_email' && !value.match(REG_EX.EMAIL)) {
      setErrors((prevState: ContactFormData) => ({ ...prevState, [name]: 'Invalid email!' }));
    } else {
      setErrors((prevState: ContactFormData) => ({ ...prevState, [name]: '' }));
    }

    setFormData((prevState: ContactFormData) => ({ ...prevState, [name]: value }));
  }

  // post form data
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let err = {
      _name: '',
      _email: '',
      _message: ''
    }

    if (!formData._name) {
      err._name = 'Required!'
    } else {
      err._name = ''
    }
    if (!formData._email) {
      err._email = 'Required!'
    } else if (!formData._email.match(REG_EX.EMAIL)) {
      err._email = 'Invalid email!'
    } else {
      err._email = ''
    }
    if (!formData._message) {
      err._message = 'Required!'
    } else {
      err._message = ''
    }

    if (!err._name && !err._email && !err._message) {
      try {
        setIsLoading(true);
        const response = await fetch(GOOGLE_SCRIPT_URL, {
          method: 'POST',
          body: JSON.stringify(formData),
          redirect: 'follow'
        });
        setIsLoading(false);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();

        if (result.status === 'success') {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
        setShowSubmitted(true);

      } catch (error) {
        setIsSuccess(false);
      }
    } else {
      setErrors(err)
    }
  };

  const handleSendNew = () => {
    isSuccess && setFormData(initialFormData);
    setShowSubmitted(false);
    setIsSuccess(false);
  }

  return (
    <StyledFormWrapper>
      {showSubmitted ? (
        <StyledMessageWrapper>
          <StyledThankYou>{isSuccess ? 'Thank You!🤘' : 'Something went Wrong!📛'}</StyledThankYou>
          <StyledMessageText>{isSuccess ? 'Your message has been accepted. You will recieve answer really soon!' : 'Your message has not reached. Something went wrong!'}</StyledMessageText>
          <StyledButton type='button' onClick={handleSendNew}>
            {isSuccess ? 'send-new-message' : 'retry'}
          </StyledButton>
        </StyledMessageWrapper>
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <StyledInputGroup>
            <label>_name:</label>
            <input name='_name' id="_name" value={formData._name} onChange={handleOnChange} />
            <span className='error'>{errors._name}</span>
          </StyledInputGroup>
          <StyledInputGroup>
            <label>_email:</label>
            <input name='_email' id="-_email" value={formData._email} onChange={handleOnChange} />
            <span className='error'>{errors._email}</span>
          </StyledInputGroup>
          <StyledInputGroup>
            <label>_message:</label>
            <textarea rows={4} name='_message' id="_message" value={formData._message} onChange={handleOnChange} />
            <span className='error'>{errors._message}</span>
          </StyledInputGroup>
          <StyledInputGroup>
            <StyledButtonWrapper>
              <StyledButton type='submit' disabled={isLoading}>
                {isLoading ? <><span>submitting.. </span> <i className="rotate ri-loader-3-fill"></i></> : 'submit-message'}
              </StyledButton>
            </StyledButtonWrapper>
          </StyledInputGroup>
        </StyledForm>
      )}
    </StyledFormWrapper>
  )
}

export default Form