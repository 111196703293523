import CodeHighlighter from '../../../../components/CodeHighlighter'

const InfoDev = () => {
  const string =
    `const overview = {
  institution: 'InfoDevelopers Pvt. Ltd',
  startDate: May 2021,
  endDate: 'Present',
  jobTitle: 'Frontent Engineer - React',
  designation: 'Engineer',
}

const keyPoints = [
  'Worked in React.js using typescript, hooks, redux, react query, formik, react-table, charts.js.',
  'Participated in creating react boilerplate and code review.',
  'Participated in creating generic component for table using react-table and forms using formik with yup validation.',
]
`

  return (
    <CodeHighlighter string={string} />
  )
}
export default InfoDev