import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { ThemeProps } from '../@types/theme'

const StyledBase = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: ${({ theme }: { theme: ThemeProps }) => theme.colors.primary1};
  border: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

const Base = (props: PropsWithChildren) => {
  return (
    <StyledBase>
      {props.children}
    </StyledBase>
  )
}

export default Base