import CodeHighlighter from "../../../../components/CodeHighlighter"

const PlusTwo = () => {

  const string =
    `const overview = {
  degree: 'Higher Secondary Examination Board (HSEB)'
  course: 'Science'
  institude: 'Ambition Academy Higher Secandary School',
  completedYear: 2016,
  degreeAwardedBy: 'National Examination Board'
}

const modules = {
  XI : [
    'English',
    'Physics',
    'Chemistry',
    'Mathematics',
    'Computer Science'
  ],
  XII: [
    'English',
    'Nepali',
    'Physics',
    'Chemistry',
    'Mathematics'
  ]
}
`

  return (
    <CodeHighlighter string={string} />
  )
}

export default PlusTwo