const colors = {
  body: '#01080E',
  primary1: '#011627',
  primary2: '#011221',
  secondary1: '#607B96',
  secondary2: '#3C9D93',
  secondary3: '#4D5BCE',
  secondary4: '#FFFFFF',
  accent1: '#FEA55F',
  accent2: '#43D9AD',
  accent3: '#E99287',
  accent4: '#C98BDF',
  lines: '#1E2D3D',
  gradient1: '#4D5BCE',
  gradient2: '#43D9AD',
  btnPrimary: '#FEA55F',
  btnPrimaryHover: '#FFAC6B',
  btnPrimaryText: '#01080E',
  btnDefault: '#1C2B3A',
  btnDefaultHover: '#263B50',
  btnDefaultText: '#FFFFFF',
  btnGhost: '#FFFFFF',
  btnGhostHover: '#FFFFFF',
  btnGhostText: '#FFFFFF',
  socialMedia: '#607B96',
  socialMediaHover: '#FFFFFF'
}

export default colors