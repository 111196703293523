import React from 'react';
import styled from 'styled-components';
import ProjectItem from './ProjectItem';
import { projects } from '../project.data';

const StyledProjectList = styled.div`
  padding: 60px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 40px;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  overflow: auto;

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    overflow: hidden;
    padding: 20px;
  }
`;

const ProjectList = ({ checkedStacks }: { checkedStacks: Array<string> }) => {
  const visibleProjects = projects.filter(d => d.technologies.some(tech => checkedStacks.includes(tech))) || projects;

  return (
    <StyledProjectList>
      {visibleProjects.length > 0
        ? visibleProjects.map(project => <ProjectItem project={project} />)
        : projects.map(project => <ProjectItem project={project} />)}
    </StyledProjectList>
  );
};

export default ProjectList;
