import { TagCloud, TagCloudOptions } from "@frank-mayer/react-tag-cloud";
import styled from "styled-components";
import { ThemeProps } from "../../@types/theme";

const StyledTagCloud = styled(TagCloud)`
  .itemClass {
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary4};
    cursor: pointer;
    &:hover{
      color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.accent2};
    }
  }
`

interface ISkillTags {
  skills: Array<string>;
}

const SkillTags = (props: ISkillTags) => {
  const { skills } = props;



  return (
    <StyledTagCloud
      options={(w: Window & typeof globalThis): TagCloudOptions => ({
        radius: Math.min(500, w.innerWidth, w.innerHeight) / (w.innerWidth > 500 ? 2 : 3),
        maxSpeed: "slow",
        itemClass: 'itemClass',
        initSpeed: 'fast',
        deceleration: 45,
      })}
    // onClick={(tag: string, ev: MouseEvent) => alert(tag)}
    // onClickOptions={{ passive: true }}
    >
      {skills}
    </StyledTagCloud>
  )
}

export default SkillTags