import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 16px;
`;

const Wrapper = (props: PropsWithChildren) => {
  return (
    <StyledWrapper>
      {props.children}
    </StyledWrapper>
  )
}

export default Wrapper