import { createGlobalStyle } from 'styled-components';
import { ThemeProps } from './@types/theme';

const GlobalStyle = createGlobalStyle`
  /* Font */
  @import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@200;300;400;500;600;700&display=swap');

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  ul,
  li,
  dl,
  dd, 
  pre {
    margin: 0;
  }

  /* Body default style */ 
  body{
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.body};
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
    font-family: 'Fira Code', monospace;
  }
  
  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }
  
  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }
  
  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }
  
  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }
  
  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* inputs */
  input, textarea {
    outline: none;
    border: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
    border-radius: 8px;
    padding: 8px 12px;
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
    background-color:  ${({ theme }: { theme: ThemeProps }) => theme.colors.primary2};

    &:focus{
      border: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.secondary1};
    }
  }

  .error {
    color: ${({ theme }: { theme: ThemeProps }) => theme.colors.accent3};
    font-size: x-small;
  }

  .rotate {
    animation: spin 0.8s infinite linear;
  }

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  textarea {
    resize:vertical
  } /* disable horizontal resize */

  /*scrollbar Width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Scrollbar Track */
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.primary1};
    outline: 1px solid ${({ theme }: { theme: ThemeProps }) => theme.colors.lines};
  }
 
  /* Scrollbar Handle */
  ::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefault}; 
  }

  /* Scrollbar Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }: { theme: ThemeProps }) => theme.colors.btnDefaultHover}; 
  }
  
  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
     scroll-behavior: auto;
    }
    
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`

export default GlobalStyle;