import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components'
import FooterContent from './components/Footer/Footer';
import NavBar from './components/Nav/NavBar';
import GlobalStyle from './GlobalStyle';
import Base from './layouts/Base';
import Footer from './layouts/Footer';
import Header from './layouts/Header';
import Main from './layouts/Main';
import Wrapper from './layouts/Wrapper';
import Router from './routes';
import theme from './theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Wrapper>
          <Base>
            <Header>
              <NavBar />
            </Header>
            <Main>
              <Router />
            </Main>
            <Footer>
              <FooterContent />
            </Footer>
          </Base>
        </Wrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
